.header_logo {
    width: 250px;
    margin-left: 3%;
    margin-top: 1%;
}
.header_divider {
    width: 100%;
    height: 15px;
    background-color: #132c83;
    margin-top: 1%;
}

.brand_logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    max-width: 100px;
    margin-top: -59px;
}

@media (max-width: 679px) {
    .header_logo {
        width: 200px;
    }
    .brand_logo {
        display: inline-block;
        margin-left: 10px;
        max-width: 60px;
        margin-top: -50px;
    }
}
