.footer_divider {
    width: 100%;
    height: 1px;
    background-color: #a0cd54;
    margin-top: 3%;
}
.links{
    margin-top: 2%;
    /*display: grid;*/
    /*grid-template-columns: 5fr .65fr .1fr .7fr 5fr;*/
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
    color: #224091;
    justify-content: center;
}
.links a{
    display: inline-block;
    float: left;
    color: #224091;
    text-decoration: underline !important;
    margin-left: .5%;
    margin-right: .5%;

}
.links a:hover {
    text-decoration: none !important;
}
